




import { type PropType, computed, defineComponent } from "@vue/composition-api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { clubApi, coachApi } from "@/lib/backend";
import type { DaosModelsMemberTracking, IomodelsCoachappIntake } from "@/lib/backend/club.api";

const TRACKING_MAP: Record<
	keyof Omit<DaosModelsMemberTracking, "id" | "memberId">,
	IomodelsCoachappIntake["diensten"][number]
> = {
	grouplessons: "groepslessen",
	measurements: "metingen",
	schemas: "schemas",
	target: "doel",
};

export default defineComponent({
	props: {
		memberId: {
			type: Number,
			required: true,
		},
		type: {
			type: String as PropType<keyof typeof TRACKING_MAP>,
			required: true,
			validator: (value: string) => Object.keys(TRACKING_MAP).includes(value),
		},
		switchDescription: {
			type: String,
			default: "Aan of uit",
		},
	},
	setup(props) {
		const queryClient = useQueryClient();

		const { status: queryStatus, data } = useQuery({
			queryKey: ["member", props.memberId, "tracking"] as const,
			queryFn: async (context) =>
				await clubApi.tracking
					.getTracking(context.queryKey[1], { signal: context.signal })
					.then((response) => response.data),
		});

		const { status: mutationStatus, mutate } = useMutation({
			mutationKey: ["member", props.memberId, "tracking"] as const,
			mutationFn: async (variables: {
				memberId: number;
				value: boolean;
				type: IomodelsCoachappIntake["diensten"][number];
			}) =>
				// FUTURE: This API call is currently not typed within the CoachApi
				await coachApi.instance.post(`/api/user/${variables.memberId}/change-tracking`, {
					type: variables.type,
					value: variables.value,
				}),
			onSettled: () =>
				queryClient.refetchQueries({ queryKey: ["member", props.memberId, "tracking"] }),
		});

		const tracking = computed({
			get: () => !!data.value?.[props.type],
			set: (value) => mutate({ memberId: props.memberId, type: TRACKING_MAP[props.type], value }),
		});

		const loading = computed(
			() => queryStatus.value === "loading" && mutationStatus.value === "loading",
		);

		return { loading, tracking };
	},
});
