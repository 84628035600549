















































import { computed, defineComponent } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import ErrorAlert from "@/components/ErrorAlert.vue";
import Alert from "@/lib/pfg/vue2/alert/Alert.vue";
import AlertDescription from "@/lib/pfg/vue2/alert/AlertDescription.vue";
import AlertTitle from "@/lib/pfg/vue2/alert/AlertTitle.vue";
import { queryOptions_personalTrainings } from "@/lib/query/functions/personal-training/all";
import Item from "./Item.vue";

export default defineComponent({
	components: {
		ErrorAlert,
		Alert,
		AlertTitle,
		AlertDescription,
		Item,
	},
	props: {
		memberId: {
			type: Number,
			required: true,
		},
	},
	setup(props) {
		const { status, data, error } = useQuery(
			queryOptions_personalTrainings(computed(() => props.memberId)),
		);

		return { props, status, data, error };
	},
});
