import type { UseQueryOptions } from "@tanstack/vue-query";
import type { MaybeRef } from "@tanstack/vue-query/build/lib/types";
import { reserveerApi } from "@/lib/backend";

const apiCall = reserveerApi.persoonlijkeTrainingen.trainingsProgrammas;
type ApiCall = typeof apiCall;
type Data = Awaited<ReturnType<ApiCall>>["data"];

export function queryOptions_personalTrainings(
	memberId: MaybeRef<Parameters<ApiCall>[0]>,
): UseQueryOptions<
	Data,
	unknown,
	Data,
	readonly ["member", typeof memberId, "personal-trainings"]
> {
	return {
		queryKey: ["member", memberId, "personal-trainings"] as const,
		queryFn: async (context) =>
			await apiCall(context.queryKey[1], { signal: context.signal }).then(
				(response) => response.data,
			),
		placeholderData: [] as Data,
		select: (data) =>
			data.sort((a, b) => new Date(b.startDatum).getTime() - new Date(a.startDatum).getTime()),
	};
}
