import { computed, unref } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { queryOptions_personalTrainings } from "@/lib/query/functions/personal-training/all";
import { queryOptions_personalTrainingCurrentDay } from "@/lib/query/functions/personal-training/current-day";

export function usePersonalTrainingData(
	memberId: Parameters<typeof queryOptions_personalTrainingCurrentDay>[0],
	programId: Parameters<typeof queryOptions_personalTrainingCurrentDay>[0],
) {
	const { data: allPersonalTrainingsData } = useQuery(queryOptions_personalTrainings(memberId));

	const personalTraining = computed(() =>
		allPersonalTrainingsData.value?.find(
			(personalTraining) => personalTraining.id === unref(programId),
		),
	);

	const { data: currentTrainingDay } = useQuery({
		...queryOptions_personalTrainingCurrentDay(memberId, programId),
		enabled: computed(() => !personalTraining.value?.voltooid),
	});

	return computed(() => {
		const id =
			currentTrainingDay.value?.programmaId || personalTraining.value?.id || unref(programId);
		const currentDay = personalTraining.value?.voltooid ? 0 : currentTrainingDay.value?.dag || 1;
		const currentWeek = personalTraining.value?.voltooid ? 0 : currentTrainingDay.value?.week || 1;
		const daysPerWeek = currentTrainingDay.value?.dagenPerWeek || 4;
		const totalWeeks =
			personalTraining.value?.zesWekenProgramma === true ? 6 : Math.max(currentWeek, 1);

		return {
			id,
			name:
				currentTrainingDay.value?.programmaTitel ||
				personalTraining.value?.titel ||
				`Schema ${unref(programId)}`,
			isCurrent: allPersonalTrainingsData.value?.[0]?.id === id,
			isLastDay: currentTrainingDay.value?.laatsteDagVanSchema || false,
			startedOn: personalTraining.value?.startDatum,
			currentWeek,
			currentDay,
			daysPerWeek,
			totalWeeks,
			completed: personalTraining.value?.voltooid ?? false,
			weeks: Array.from({ length: totalWeeks }, (_, weekIndex) => {
				const week = weekIndex + 1;
				const days = Array.from({ length: daysPerWeek }, (_, dayIndex) => {
					const day = dayIndex + 1;

					return {
						number: day,
						completed:
							personalTraining.value?.voltooid ||
							week < currentWeek ||
							(week <= currentWeek && day < currentDay),
					};
				});

				return {
					number: week,
					completed: personalTraining.value?.voltooid || days.every((day) => day.completed),
					days,
				};
			}),
		};
	});
}
