







































import { type PropType, defineComponent } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import List from "@/components/Schema/List/index.vue";
import UpcomingAppointments from "@/components/Schema/UpcomingAppointments.vue";
import TrackingToggle from "@/components/TrackingToggle.vue";
import { clubApi } from "@/lib/backend";
import type { DaosModelsMemberTracking } from "@/lib/backend/club.api";
import { buttonVariants } from "@/lib/pfg/components/button";
import type { RouteProps } from "@/router/types";

export default defineComponent({
	components: {
		TrackingToggle,
		UpcomingAppointments,
		List,
	},
	props: {
		memberId: {
			type: Number as PropType<RouteProps<"member">["memberId"]>,
			required: true,
		},
	},
	setup(props) {
		const { status, data: isTracking } = useQuery({
			queryKey: ["member", props.memberId, "tracking"] as const,
			queryFn: async (context) =>
				await clubApi.tracking
					.getTracking(context.queryKey[1], { signal: context.signal })
					.then((response) => response.data),
			select: (data: DaosModelsMemberTracking) => data.schemas,
		});

		return { props, buttonVariants, status, isTracking };
	},
});
