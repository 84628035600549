





















































































































































import { computed, defineComponent } from "@vue/composition-api";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useRouteQuery } from "@vueuse/router";
import { pfgOutlineSchema } from "@/components/icons/pfg/outline/schema";
import CheckCircleIcon from "@/lib/@heroicons/vue/20/solid/CheckCircleIcon.vue";
import ChevronDownIcon from "@/lib/@heroicons/vue/20/solid/ChevronDownIcon.vue";
import { usePersonalTrainingData } from "@/lib/hooks/usePersonalTrainingData";
import { buttonVariants } from "@/lib/pfg/components/button";
import Card from "@/lib/pfg/vue2/card/Card.vue";
import CardContent from "@/lib/pfg/vue2/card/CardContent.vue";
import CardDescription from "@/lib/pfg/vue2/card/CardDescription.vue";
import CardTitle from "@/lib/pfg/vue2/card/CardTitle.vue";
import { cx } from "@/lib/style/cva.config";

export default defineComponent({
	components: {
		FontAwesomeIcon,
		Card,
		CardTitle,
		CardDescription,
		CardContent,
		CheckCircleIcon,
		ChevronDownIcon,
	},
	props: {
		memberId: {
			type: Number,
			required: true,
		},
		personalTrainingId: {
			type: Number,
			required: true,
		},
		canExpand: {
			type: Boolean,
			default: true,
		},
		initialExpanded: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, { root }) {
		const data = usePersonalTrainingData(
			computed(() => props.memberId),
			computed(() => props.personalTrainingId),
		);

		const queryWeek = useRouteQuery<unknown, number | undefined>(
			`program_${props.personalTrainingId}`,
			undefined,
			{
				route: root.$route,
				router: root.$router,
				transform: Number,
			},
		);

		const expanded = computed(() => props.initialExpanded || !!queryWeek.value);

		const selectedWeek = computed(() => queryWeek.value || data.value.currentWeek || 1);
		const selectedDays = computed(
			() => data.value.weeks.find((week) => week.number === selectedWeek.value)?.days ?? [],
		);

		const weekLabel = computed(
			() =>
				Intl.NumberFormat("nl", {
					style: "unit",
					unit: "week",
					unitDisplay: "long",
				})
					.formatToParts(1)
					.find((part) => part.type === "unit")?.value,
		);

		const dayLabel = computed(
			() =>
				Intl.NumberFormat("nl", {
					style: "unit",
					unit: "day",
					unitDisplay: "long",
				})
					.formatToParts(1)
					.find((part) => part.type === "unit")?.value,
		);

		return {
			pfgOutlineSchema,
			cx,
			buttonVariants,
			queryWeek,
			expanded,
			data,
			selectedWeek,
			selectedDays,
			weekLabel,
			dayLabel,
		};
	},
});
