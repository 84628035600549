import type { UseQueryOptions } from "@tanstack/vue-query";
import type { MaybeRef } from "@tanstack/vue-query/build/lib/types";
import { reserveerApi } from "@/lib/backend";

const apiCall = reserveerApi.persoonlijkeTrainingen.getHuidigeTrainingsDag;
type ApiCall = typeof apiCall;
type Data = Awaited<ReturnType<ApiCall>>["data"];

export function queryOptions_personalTrainingCurrentDay(
	memberId: MaybeRef<Parameters<ApiCall>[0]>,
	programId: MaybeRef<Parameters<ApiCall>[1]>,
): UseQueryOptions<
	Data,
	unknown,
	Data,
	readonly ["member", typeof memberId, "personal-trainings", typeof programId]
> {
	return {
		queryKey: ["member", memberId, "personal-trainings", programId] as const,
		queryFn: async (context) =>
			await apiCall(context.queryKey[1], context.queryKey[3], {
				signal: context.signal,
			}).then((response) => response.data),
	};
}
