var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{directives:[{name:"tw-merge",rawName:"v-tw-merge"}],staticClass:"flex flex-col gap-0 p-0",style:({ height: 'fit-content' })},[_c('button',{staticClass:"text-inherit flex transform-none flex-col gap-y-1.5 p-4",attrs:{"type":"button","aria-expanded":_vm.expanded,"aria-label":_vm.expanded ? 'Collapse' : 'Expand',"disabled":!_vm.canExpand},on:{"click":function($event){_vm.queryWeek = _vm.expanded ? undefined : _vm.data.completed ? 1 : _vm.data.currentWeek}}},[_c('div',{staticClass:"flex w-full justify-between gap-4"},[_c("h4",{tag:"CardTitle",staticClass:"m-0 truncate normal-case"},[_vm._v(" "+_vm._s(_vm.data.name)+" ")]),(_vm.canExpand)?_c('div',{staticClass:"grid h-6 w-6 place-content-center",style:({ transform: _vm.expanded ? 'rotate(180deg)' : 'rotate(0deg)' })},[_c('ChevronDownIcon')],1):_vm._e()],1),_c('div',{staticClass:"w-full flex flex-row justify-between gap-4"},[_c('CardDescription',{staticClass:"m-0 text-left"},[_vm._v(" "+_vm._s(Math.max( _vm.data.completed ? _vm.data.totalWeeks : _vm.data.currentWeek - 1 + (_vm.data.currentDay - 1 >= _vm.data.daysPerWeek ? 1 : 0), 0 ))+" van de "+_vm._s(Intl.NumberFormat("nl", { style: "unit", unit: "week", unitDisplay: "long" }).format( _vm.data.totalWeeks ))+" afgerond ")]),(_vm.data.startedOn)?_c('CardDescription',{staticClass:"m-0 text-right"},[_vm._v(" Begonnen op "),_c('time',{attrs:{"datetime":_vm.data.startedOn}},[_vm._v(" "+_vm._s(new Date(_vm.data.startedOn).toLocaleString("nl", { dateStyle: "short" }))+" ")])]):_vm._e()],1),_c('div',{staticClass:"h-1 w-full rounded-full bg-purple-lighter",style:({ contain: 'paint' })},[_c('div',{staticClass:"from-purple-gradient-start to-purple-gradient-end h-full bg-gradient-to-r",style:({
					width:
						Math.max(
							2.5,
							(_vm.data.completed
								? 1
								: ((_vm.data.currentWeek - 1) * _vm.data.daysPerWeek + _vm.data.currentDay - 1) /
									(_vm.data.totalWeeks * _vm.data.daysPerWeek)) * 100
						) + '%',
				})})])]),_c('CardContent',{directives:[{name:"show",rawName:"v-show",value:(_vm.expanded),expression:"expanded"}],staticClass:"-mt-2 flex flex-col gap-1 p-4 pt-3"},[_c('div',{staticClass:"-mx-4 -my-3 flex flex-row gap-2 overflow-x-auto px-4 py-3"},_vm._l((_vm.data.weeks),function(week,index){return _c('div',{key:week.number,class:_vm.cx(
						'-m-1 flex-1 p-1',
						week.number === _vm.selectedWeek && 'week-corners rounded-t-2xl bg-purple-lighter'
					),attrs:{"data-place":index === 0 ? 'first' : index === _vm.data.totalWeeks - 1 ? 'last' : undefined}},[_c('button',{class:_vm.buttonVariants({
							variant: 'outline',
							class: _vm.cx(
								'relative h-full flex-col rounded-xl text-center text-sm font-bold transition-colors w-full transform-none',
								week.completed && 'opacity-60',
								_vm.data.isCurrent && week.number === _vm.data.currentWeek
									? 'p-1.75 from-purple-gradient-start to-purple-gradient-end bg-gradient-to-r text-white hover:text-white'
									: 'p-1.5 border border-solid border-purple-lighter bg-white text-black hover:bg-black-50 active:bg-black-50'
							),
						}),attrs:{"type":"button"},on:{"click":function($event){_vm.queryWeek = week.number}}},[_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(_vm.weekLabel))]),_c('span',[_vm._v(_vm._s(week.number))]),(week.completed)?_c('CheckCircleIcon',{staticClass:"pointer-events-none absolute -right-1 -top-1 text-blue bg-white border-0 rounded-full"}):_vm._e()],1)])}),0),_c('div',{class:_vm.cx(
					'-mx-1 flex flex-row gap-2 rounded-2xl bg-purple-lighter p-1 overflow-x-auto',
					_vm.selectedWeek === 1 ? 'rounded-tl-none' : '',
					_vm.selectedWeek === _vm.data.totalWeeks ? 'rounded-tr-none' : ''
				)},_vm._l((_vm.selectedDays),function(day){return _c(_vm.data.completed ? 'div' : 'router-link',{key:day.number,tag:"div",class:_vm.cx(
						'text-inherit relative flex flex-1 flex-col items-center gap-2.5 rounded-xl border border-solid border-purple-lighter bg-white px-2 py-3 text-center text-sm font-bold transition-colors hover:bg-black-50 active:bg-black-50',
						day.completed && 'opacity-60',
						_vm.data.isCurrent &&
							_vm.selectedWeek === _vm.data.currentWeek &&
							day.number === _vm.data.currentDay &&
							'from-purple-gradient-start to-purple-gradient-end bg-gradient-to-r text-white'
					),attrs:{"to":_vm.data.completed
						? undefined
						: {
								name: 'Member program',
								params: {
									programId: _vm.data.id,
									week: _vm.selectedWeek,
									day: day.number,
								},
							}}},[_c('FontAwesomeIcon',{staticClass:"w-5 h-5",attrs:{"icon":_vm.pfgOutlineSchema}}),_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(_vm.dayLabel)+" "+_vm._s(day.number))]),(day.completed)?_c('CheckCircleIcon',{staticClass:"pointer-events-none absolute -right-1 -top-1 text-blue bg-white border-0 rounded-full"}):_vm._e()],1)}),1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }